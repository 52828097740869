import { Jumbotron } from "react-bootstrap";
import React from "react";

export default function TextJumbotron({ heading, body }) {
  return (
    <Jumbotron className="bg-white">
      <div className="text-center">
        <h1>{heading}</h1>
        <p>{body} </p>
      </div>
    </Jumbotron>
  );
}
