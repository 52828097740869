import React from "react";
import BaseLayout from "../components/layouts/baseLayout";
import ImageTextDuo from "../components/about/imageTextDuo";
import { COMPANY_NAME } from "../constants/constants";
import TextJumbotron from "../components/util/textJumbotron";
import AboutHighlights from "../components/about/aboutHighlights";

export default function AboutPage({ data }) {
  return (
    <BaseLayout pageTitle={`About ${COMPANY_NAME}`}>
      <TextJumbotron heading={`About ${COMPANY_NAME}`} />
      <ImageTextDuo />
      <AboutHighlights />
    </BaseLayout>
  );
}
