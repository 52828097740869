import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeartIcon from "../../images/svg/bookmark-heart.inline.svg";
import UpsideDownSmileyIcon from "../../images/svg/upside-down-smile.inline.svg";
import CameraIcon from "../../images/svg/camera.inline.svg";
import MusicNoteIcon from "../../images/svg/music-note.inline.svg";
import BullseyeIcon from "../../images/svg/bullseye.inline.svg";
import EmojiSunglassesIcon from "../../images/svg/emoji-sunglasses.inline.svg";
import PersonBoundingIcon from "../../images/svg/person-bounding.inline.svg";
import JournalIcon from "../../images/svg/journal.inline.svg";
import styled from "styled-components";

const ColoredContainer = styled(Container)`\
  ;
    background-color: ${(props) => props.backgroundColor || "white"};
    padding-left: ${(props) => props.paddingleft};
    padding-right: ${(props) => props.paddingRight};
    padding-top: ${(props) => props.paddingTop || "10rem"};
    padding-bottom: ${(props) => props.paddingBottom || "10rem"};
  `;



export default function AboutHighlights() {
  return (
    <section className="pb-3">
      {/* Todo Change background to Teal color */}
      <Container className="bg-primary text-white p-5">
        <h2 className="text-center mb-5">Things We Do Best</h2>
        <Row className="text-center">
          <Col xs={12} sm={3}>
            <HeartIcon />
            <h3>Learning</h3>
            <p>
              Certified and experienced teachers Licensed under Alberta Child
              Care Licensing Act.{" "}
            </p>
          </Col>
          <Col xs={12} sm={3}>
            <UpsideDownSmileyIcon />

            <h3>Twist Happiness</h3>
            <p>
              A Low teacher-student ratio ensures that each student receives the
              attention they deserve.{" "}
            </p>
          </Col>
          <Col xs={12} sm={3}>
            <CameraIcon />

            <h3>Memories</h3>
            <p>Activities centres designed to teach a variety of skills.</p>
          </Col>
          <Col xs={12} sm={3}>
            <MusicNoteIcon />
            <h3>Sing & Dance</h3>
            <p>
              Interactive activities in science, math, music, art and field trips
            </p>
          </Col>
        </Row>
      </Container>

      <ColoredContainer backgroundColor="#f7a568">
        <Row>
          <Col className="text-white text-center">
            <JournalIcon />
            <h2>Our Philosphy</h2>
            <p className="lead">
              At Great Minds, the love and care is enormous - you will feel it
              right from your very first step in! We treat your children like
              our very own. We tend to their emotional needs, promote their
              personal development and provide a caring environment that helps
              enhance their confidence through independence. We aim to teach
              children, at such an early age, numeracy, reading, writing and
              arithmetic. We aim to develop the children through academic
              activities that nurtures their cognitive development, encourages
              curiosity, discovery and problem solving which promotes individual
              growth and development of a self-image.
            </p>
          </Col>
        </Row>
      </ColoredContainer>

      <ColoredContainer backgroundColor="#2abbc5">
        <Row>
          <Col className="text-white text-center">
            <BullseyeIcon />
            <h2>Our Mission</h2>
            <p className="lead">
              {/* At Great Minds, the love and care is enormous - you will feel it
              right from your very first step in! We treat your children like
              our very own. We tend to their emotional needs, promote their
              personal development and provide a caring environment that helps
              enhance their confidence through independence. We aim to teach
              children, at such an early age, numeracy, reading, writing and
              arithmetic. We aim to develop the children through academic
              activities that nurtures their cognitive development, encourages
              curiosity, discovery and problem solving which promotes individual
              growth and development of a self-image. */}
              Our mission is to create a setting that engages and stimulates physical, social,
              emotional, language, self-help and cognitive development through meaningful
              play for the total development of the child. The program also aims to teach
              children reading, writing and arithmetic at such an early age.
            </p>
          </Col>
        </Row>
      </ColoredContainer>

      <ColoredContainer backgroundColor="#f7a568">
        <Row>
          <Col className="text-white text-center">
            <PersonBoundingIcon />
            <h2>Individualized Attention</h2>
            <p className="lead">
              {/* Our mission is to create a setting that engages and stimulates
              physical, social, emotional, language, self-help and cognitive
              development through meaningful play for the total development of
              the child. We believe our program offers a meaningful play
              platform that encourages curiosity, discovery and problem solving
              which promotes individual growth and development of a self image.
              The program also aims to teach children reading, writing and
              arithmetic at such an early age. */}
              With our very low student:teacher ratios, we can focus on each
              child as an individual while providing a very safe and secure
              environment where exploration is the key to set our imaginations free.
            </p>
          </Col>
        </Row>
      </ColoredContainer>

      <ColoredContainer backgroundColor="#2abbc5">
        <Row>
          <Col className="text-white text-center">
            <EmojiSunglassesIcon />
            <h2>Staff</h2>
            <p className="lead">
              We are enormously proud of our dedicated and energetic staff. Our
              teachers are carefully selected for their strong interest in Early
              Childhood Education, and for their warmth and enthusiasm. By
              bringing varied and extensive skills and experience to their work,
              they make Great Minds the joyful and creative environment that it
              is.
            </p>
          </Col>
        </Row>
      </ColoredContainer>
    </section>
  );
}
