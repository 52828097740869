import React from "react";
import ChildInFoilageImage from "../images/childInFoilage";
import ChildReadingAtlasImage from "../images/childReadingAtlas";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

export default function ImageTextDuo() {
  return (
    <Container>
      <Row class="row">
        <Col xs={12} md={6}>
          <ChildInFoilageImage className="rounded" />
          <h2 class="text-center pt-3">Preschool</h2>
          <p>
            Our <Link to="/preschool/how-it-works">preschool classes</Link> are
            for children ages 3 through 5. Our weekly themes provide hours of
            fun and learning for the children. The day is filled with music,
            stories, crafts, group games, play, learn and lots more! Great Minds
            offers more than just a place where your child can kick start their
            learning process. We also provide your children with a safe, healthy
            and loving environment. Our classrooms are suitable for acquiring
            social skills and development through creative exploration.{" "}
          </p>
        </Col>
        <Col xs={12} md={6}>
          <h2 class="text-center">Tutoring</h2>
          <p className="pb-3">
            Great Minds Academy provides{" "}
            <Link to="/tutoring/how-it-works">top-quality tutoring</Link> and
            enrichment programs to students from <strong>kindergarten</strong> to <strong>grade 12</strong> and
            beyond! We specialize in <strong>mathematics, english, biology, chemistry,
            physics</strong> and <strong>high school exam preparation</strong>. We offer a variety of
            customized after school class options including in-person, online or
            both.{" "}
          </p>
          <ChildReadingAtlasImage className="rounded" />
        </Col>
      </Row>
    </Container>
  );
}
